export function useGetSeoTitleAppendix(
  titleLength: number,
  options?: { withoutSpacer?: boolean },
) {
  const siteIdent = useSiteIdent();
  let titleAppendix = !options?.withoutSpacer ? ' | ' : '';

  switch (siteIdent) {
    case SiteIdent.default:
      titleAppendix = titleAppendix.concat('vanderven.de');
      break;
    case SiteIdent.miniluDe:
      titleAppendix = titleAppendix.concat('minilu.de');
      break;
    case SiteIdent.miniluNl:
      titleAppendix = titleAppendix.concat('minilu.nl');
      break;
    case SiteIdent.miniluAt:
      titleAppendix = titleAppendix.concat('minilu.at');
      break;
    default:
      return titleAppendix.concat('Dental Online Shop');
  }

  if (titleLength < 26) {
    return titleAppendix.concat(' - Dental Online Shop');
  } else if (titleLength < 31) {
    return titleAppendix.concat(' - Dental Online');
  } else if (titleLength < 38) {
    return titleAppendix.concat(' - Dental');
  } else {
    return titleAppendix;
  }
}

export function getHostSSRSafe() {
  return import.meta.server
    ? useRequestHeaders(['host']).host
    : window.location.origin;
}
